import { useMemo } from "react";
import Button from "./Button";
import Pagination from "../pagination/Pagination";
let PageSize = 10;

function Offers({
    items,
    editItem,
    onEdit,
    addCurrentPage,
    currentPage,
    onDelete,
    getQuoNo,
}) {
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return items.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, items]);

    const onClickAdd = () => {
        editItem(true);
    };

    const searchItem = (str) => {
        items = items.filter(
            (a) =>
                a.client.toLowerCase().includes(str.toLowerCase()) ||
                a.project_name.toLowerCase().includes(str.toLowerCase()) ||
                a.description.toLowerCase().includes(str.toLowerCase()) ||
                a.work_type.toLowerCase().includes(str.toLowerCase())
        );
    };

    const trClass = (item) => {
        let cls = "";
        cls = item.toLowerCase().includes("pending") ? "table-warning" : cls;
        cls = item.toLowerCase().includes("awarded") ? "table-success" : cls;
        cls = item.toLowerCase().includes("cancelled") ? "table-dark" : cls;
        return cls;
    };

    return (
        <div className="container">
            <div class="row justify-content-between mt-2">
                <div class="col-4">
                    <Button
                        classtitle="btn-outline-success mt-2"
                        title="Add Request"
                        onClick={onClickAdd}
                    />
                </div>
                <div class="col-4">
                    <div class="row g-3 align-items-center">
                        <div class="col-auto">
                            <label for="inputPassword6" class="col-form-label">
                                Search
                            </label>
                        </div>
                        <div class="col-auto">
                            <input
                                type="test"
                                id="inputPassword6"
                                class="form-control"
                                aria-describedby="passwordHelpInline"
                                onChange={(e) => searchItem(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Client</th>
                        <th>Project</th>
                        <th>Scope of Work</th>
                        <th>Service Type</th>
                        <th>Quotation Date</th>
                        <th>Quotation No#</th>
                        <th>Quotation Value</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTableData &&
                        currentTableData.map((o) => (
                            <tr key={o.id} className={trClass(o.status)}>
                                <td>{o.rec_date}</td>
                                <td>{o.client}</td>
                                <td>{o.project_name}</td>
                                <td>{o.description}</td>
                                <td>{o.work_type}</td>
                                <td>{o.quo_date}</td>
                                <td>
                                    {(o.quo_no !== null || o.quo_no !== "") && (
                                        <>{o.quo_no}</>
                                    )}
                                    {(o.quo_no === null || o.quo_no === "") && (
                                        <>
                                            <Button
                                                classtitle="btn-outline-warning btn-sm"
                                                title="Generate"
                                                onClick={() => getQuoNo(o.id)}
                                            />
                                        </>
                                    )}
                                </td>
                                <td>{o.quo_values}</td>
                                <td>{o.status}</td>
                                <td>
                                    <Button
                                        classtitle="btn-outline-info btn-sm"
                                        title="fa-edit"
                                        onClick={() => onEdit(o)}
                                    />
                                    <Button
                                        classtitle="btn-outline-danger btn-sm"
                                        title="fa-trash-alt"
                                        onClick={() => onDelete(o.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={items.length}
                pageSize={PageSize}
                onPageChange={(page) => addCurrentPage(page)}
            />
        </div>
    );
}

export default Offers;
